.section__title {
  font-weight: bold;
  text-align: center;
  color: var(--text-1);
  text-indent: 0;
  margin-bottom: 2rem;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  direction: ltr;
}
.link {
  width: 50%;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.link:hover .link-data {
  color: var(--blue-1);
}
.link:hover .icons {
  filter: none;
}
.icons {
  width: 50px;
  transition: 0.3s;
  filter: grayscale(100%) opacity(50%);
}
.icon__title {
  display: none;
  padding-top: 0.8rem;
  transition: 0.5s;
}
.contact--link {
  display: flex;
  flex-direction: row;
  width: 50px;
  align-items: center;
}
.link-data {
  margin: 0 1rem;
  text-decoration: none;
  color: var(--text-2);
  word-break: break-all;
}

@media only screen and (max-width: 900px) {
  .links {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .link {
    width: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .icons {
    filter: none;
  }
}
@media only screen and (max-width: 500px) {
  .link {
    gap: 8px;
  }
  .link-data {
    margin: 0rem;
  }
  .section__title {
    margin-bottom: 1rem;
  }
  .icons {
    width: 40px;
  }
}
@media only screen and (max-width: 320px) {
  .link {
    flex-direction: column;
  }
}
