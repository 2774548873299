/* @import "../UI/Allcolors.css"; */
.article__item {
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;
}
.article__item:not(:last-child) {
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 2px solid;
  border-color: var(--grey-1);
}
.article__image {
  width: 100%;
  text-align: center;
}
.article__image img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  transform: none;
  transition: all 0.3s;
}
.article__image img:hover {
  transform: scale(103%);
}
.article__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
}
.article__title {
  background-color: var(--blue-1);
  box-shadow: 0px 0px 25px 0px rgb(41 113 161 / 40%);
  color: #fff;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  transition: all 0.3s;
  transform: scale(100%) translate3d(0, 0, 0);
}
.article__title:hover {
  transform: scale(103%) translate3d(0, 0, 0);
}
.article__info {
  width: 100%;
}
.article__description a {
  color: var(--blue-1);
}
.article__description ul {
  padding: 0 3rem 1rem 3rem;
}
.article__description li {
  text-indent: 0;
}
.languages {
  margin-top: 10px;
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  direction: ltr;
}
.language {
  margin: 4px;
  padding: 4px 16px;

  border-radius: 100px;
  font-size: 0.9rem;
}
.react {
  background-color: #2dc9f0;
  color: #fff;
}
.js {
  background-color: #f0db4f;
  color: #323330;
}
.html {
  background-color: #b84646;
  color: #fff;
}
.css {
  background-color: #6d064b;
  color: #fff;
}
.rn {
  background-color: #30813b;
  color: #fff;
}
.pwa {
  background-color: #2763d1;
  color: #fff;
}
@media only screen and (max-width: 1000px) {
  .article__item {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  .article__body {
    padding-left: 0;
    padding-top: 2rem;
  }
  .article__info {
    align-self: flex-start;
  }
}
@media only screen and (max-width: 700px) {
  .article__body {
    gap: 1.5rem;
    padding-top: 1rem;
  }
  .article__title {
    padding: 0.5rem 1.3rem;
    font-size: 1rem;
  }
  .language {
    margin: 4px;
    padding: 4px 9px;
    font-size: 0.8rem;
  }
  .article__description ul {
    padding: 0 2rem 1rem 2rem;
  }
}
@media only screen and (max-width: 500px) {
  .article__item:not(:last-child) {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
