nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.header__list {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
}

.list__item,
.list__item:active,
.list__item:link {
  font-size: 1.6rem;
  color: var(--text-1);
  user-select: none;
  width: 150px;
  text-align: center;
  padding-bottom: 16px;
  /* padding-top: 16px; */
  text-decoration: none;
  font-weight: 400;
}

.underline {
  display: block;
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 4px;
  background: var(--blue-1);
  width: 150px;
  pointer-events: none;
  transition: transform 0.5s ease-in-out;
}
.underline:nth-child(1) {
  transition: calc(0.5s * 0.8);
}
.underline:nth-child(2) {
  transition: calc(0.5s * 1.2);
}
.position0 {
  transform: translate3d(0, 0, 0);
}
.position1 {
  transform: translate3d(100%, 0, 0);
}
.position2 {
  transform: translate3d(200%, 0, 0);
}
.position3 {
  transform: translate3d(300%, 0, 0);
}
.rtl {
  flex-direction: row-reverse !important;
}
@media only screen and (max-width: 700px) {
  .underline {
    display: none;
  }

  .header__list {
    justify-content: center;
  }
  .list__item,
  .list__item:active,
  .list__item:link {
    margin: 0 10px;
    padding: 6px 0px;
    margin-bottom: 14px;
    font-size: 1.2rem;
    width: 40%;
    background-color: var(--grey-2);
    border-radius: 5px;
    transition: all 0.3s;
  }
  .list__item--active {
    background-color: var(--blue-1) !important;
    color: white !important;
  }
}
@media only screen and (max-width: 440px) {
  .header__list {
    width: 80%;
    margin: 0 auto;
  }
  .list__item,
  .list__item:active,
  .list__item:link {
    width: 100%;
  }
}
