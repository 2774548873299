/* Generated by script */
@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazirmatn;
  src: url("fonts/Vazirmatn-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Vazirmatn", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-primary1);
  overflow-y: overlay;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--grey-3);
  border-radius: 20px;
  border: 8px solid var(--bg-primary1);
}

code {
  font-family: source-code-pro, "Vazirmatn", Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
* {
  margin: 0;
  padding: 0;
}
