header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header__image {
  width: 150px;
  height: auto;
  border-radius: 500px;
  box-shadow: 10px 10px 25px -6px rgba(100, 100, 100, 0.4),
    -10px -10px 25px -6px rgb(255 255 255 / 80%);
}
.header__box {
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
}
.header__title {
  font-weight: 700;
  font-size: 2.2rem;
  color: var(--text-1);
}
.header__subtitle {
  margin-top: 10px;
  font-size: 1.5rem;
  color: var(--text-2);
}
.rtl {
  flex-direction: row-reverse !important;
}
@media only screen and (max-width: 700px) {
  header {
    margin-bottom: 1.5rem;
  }
  .header-top {
    flex-direction: column;
    align-items: center;
  }
  .header__image {
    margin-bottom: 2rem;
    width: 100px;
  }
  .header__box {
    align-items: center;
    line-height: 2rem;
    margin: 0rem;
  }
  .header__title {
    font-size: 1.6rem;
    text-align: center;
  }
  .header__subtitle {
    margin-top: 0px;
    font-size: 1.3rem;
    text-align: center;
  }
}
@media only screen and (max-width: 440px) {
  header {
    border-bottom: 3px solid var(--grey-1);
    padding: 1rem;
    margin-bottom: 0rem;
  }
}
