.header {
  color: var(--text-1);
  margin-bottom: 1.3rem;
}

p:not(:last-child) {
  margin-bottom: 0.8rem;
}
.link {
  color: var(--blue-1);
  text-decoration: none;
}
