.skill-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
}

.skill-box ul {
  padding: 0 3rem 1rem 3rem;
}
.skill-box:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 3rem;
}
.skill-text {
  flex: 5;
}
.app-icons {
  direction: ltr;
  flex: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.app-icons__item {
  height: 60px;
  margin: 10px;
}
@media only screen and (max-width: 1000px) {
  .skill-box {
    flex-direction: column;
  }
  .app-icons {
    width: 100%;
  }
  .app-icons__item {
    height: 50px;
  }
}
@media only screen and (max-width: 700px) {
  .app-icons__item {
    height: 40px;
    margin: 6px;
  }
  .skill-box ul {
    padding: 0 2rem 1rem 2rem;
  }
}
