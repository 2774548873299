@import "./components/UI/Allcolors.css";
.App {
  padding: 70px 30px;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
}
.main {
  box-shadow: 10px 10px 25px -6px rgb(156 156 156 / 20%),
    -10px -10px 25px -6px rgb(255 255 255 / 70%);
  background: var(--bg-primary1);
  padding: 3rem;
  border-radius: 10px;
  font-size: 1.4rem;
  line-height: normal;
  color: var(--text-2);
}
.fa-text {
  font-size: 1.3rem;
}
p:not(li) {
  text-indent: 1rem;
}
a {
  text-decoration: none;
}

.rtl {
  direction: rtl;
}
@media only screen and (max-width: 1000px) {
  .main {
    padding: 2rem;
    font-size: 1.3rem;
  }
  .fa-text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 700px) {
  .App {
    padding: 50px 20px;
  }
  .main {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 440px) {
  .App {
    padding: 10px 0px 0px;
  }
  .main {
    box-shadow: none;
    padding: 30px;
    border-radius: 0;
    background-color: var(--bg-primary2);
  }
}
