.circle {
  box-shadow: 10px 10px 25px -6px rgba(78, 78, 78, 0.5),
    -10px -10px 25px -6px rgb(255 255 255 / 80%);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-2);
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: var(--grey-2);
}
@media only screen and (max-width: 500px) {
  .circle {
    width: 40px;
    height: 40px;
    top: 1.5rem;
    right: 1.5rem;
  }
}
