:root {
  --bg-primary1: #e9e9e9;
  --bg-primary2: #f3f3f3;
  --text-1: #555;
  --text-2: #777;

  --grey-1: #ccc;
  --grey-2: #d4d4d4;
  --grey-3: #b6b6b6;

  --blue-1: #2971a1;
}
